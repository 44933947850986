import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Cell,
} from 'recharts';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload;
    const wellDepth = payload[0].payload.remaining + value; // Calculating well depth

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          fontSize: '12px',
        }}
      >
        <p className="label">
          {`${name}:`}
          <b>{` ${value}m`}</b>
        </p>
        <p>
          Well depth:<b>{` ${wellDepth}m`}</b>
        </p>
      </div>
    );
  }

  return null;
};

const WaterLevelStackedBarChart = ({
  avgWaterLevel,
  wellWaterLevel,
  wellDepth,
}) => {
  const data = [
    {
      name: 'Well level',
      value: wellWaterLevel, // Actual well level
      remaining: wellDepth - wellWaterLevel, // White portion for Well level
    },
    {
      name: 'Average level',
      value: avgWaterLevel, // Actual average level
      remaining: wellDepth - avgWaterLevel, // White portion for Average level
    },
  ];

  const fillMapping = {
    'Well level': 'url(#wellLevel)',
    'Average level': '#FACDCD',
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{ top: 0, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="wellLevel" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#F15033" />
            <stop offset="100%" stopColor="#EEB0B0" />
          </linearGradient>
        </defs>

        <XAxis dataKey="name" hide />
        <YAxis domain={[0, wellDepth]} hide />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="value" stackId="a" barSize={30}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={fillMapping[entry.name]} />
          ))}
        </Bar>
        <Bar dataKey="remaining" stackId="a" barSize={30} fill="#FFFFFF" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WaterLevelStackedBarChart;
